import React, { useState } from "react";
import axios from "axios";
import './AddEmployee.module.css';


const AddEmployee = () => {
  const [employee, setEmployee] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    position: "",
    dateOfJoining: "",
    pan: "",
    aadhar: "",
    salary: "",
    image: null,
    pfNumber: "",
    esiNumber: "",
    accountNumber: "", // New field
    bankName: "" // New field
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployee({ ...employee, [name]: value });

    if (!value) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEmployee({ ...employee, image: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    Object.keys(employee).forEach((key) => formData.append(key, employee[key]));

    try {
      await axios.post("https://megafamilyfoundation.org/api/employees", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("Employee added successfully!");
      setEmployee({
        name: "",
        phone: "",
        email: "",
        address: "",
        position: "",
        dateOfJoining: "",
        pan: "",
        aadhar: "",
        salary: "",
        image: null,
        pfNumber: "",
        esiNumber: "",
        accountNumber: "", // New field
    bankName: "" // New field
      });
      setImagePreview(null);
      setErrors({});
    } catch (error) {
      console.error("There was an error uploading the data!", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="form-title">Add Employee</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="employee-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter employee name"
            value={employee.name}
            onChange={handleInputChange}
            required
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="Enter phone number"
            value={employee.phone}
            onChange={handleInputChange}
            required
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter email address"
            value={employee.email}
            onChange={handleInputChange}
            required
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            name="address"
            id="address"
            placeholder="Enter address"
            value={employee.address}
            onChange={handleInputChange}
            required
          />
          {errors.address && <span className="error">{errors.address}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="position">Position</label>
          <input
            type="text"
            name="position"
            id="position"
            placeholder="Enter position"
            value={employee.position}
            onChange={handleInputChange}
            required
          />
          {errors.position && <span className="error">{errors.position}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="dateOfJoining">Date of Joining</label>
          <input
            type="date"
            name="dateOfJoining"
            id="dateOfJoining"
            value={employee.dateOfJoining}
            onChange={handleInputChange}
            required
          />
          {errors.dateOfJoining && <span className="error">{errors.dateOfJoining}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="accountNumber">A/C Number</label>
          <input
            type="text"
            name="accountNumber"
            id="accountNumber"
            placeholder="Enter account number"
            value={employee.accountNumber}
            onChange={handleInputChange}
            required
          />
          {errors.accountNumber && <span className="error">{errors.accountNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="bankName">Bank Name</label>
          <input
            type="text"
            name="bankName"
            id="bankName"
            placeholder="Enter bank name"
            value={employee.bankName}
            onChange={handleInputChange}
            required
          />
          {errors.bankName && <span className="error">{errors.bankName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="pan">PAN</label>
          <input
            type="text"
            name="pan"
            id="pan"
            placeholder="Enter PAN"
            value={employee.pan}
            onChange={handleInputChange}
            required
          />
          {errors.pan && <span className="error">{errors.pan}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="aadhar">Aadhar</label>
          <input
            type="text"
            name="aadhar"
            id="aadhar"
            placeholder="Enter Aadhar"
            value={employee.aadhar}
            onChange={handleInputChange}
            required
          />
          {errors.aadhar && <span className="error">{errors.aadhar}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="pfNumber">PF Number</label>
          <input
            type="text"
            name="pfNumber"
            id="pfNumber"
            placeholder="Enter PF Number"
            value={employee.pfNumber}
            onChange={handleInputChange}
            required
          />
          {errors.pfNumber && <span className="error">{errors.pfNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="esiNumber">ESI Number</label>
          <input
            type="text"
            name="esiNumber"
            id="esiNumber"
            placeholder="Enter ESI Number"
            value={employee.esiNumber}
            onChange={handleInputChange}
            required
          />
          {errors.esiNumber && <span className="error">{errors.esiNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="salary">Salary</label>
          <input
            type="number"
            name="salary"
            id="salary"
            placeholder="Enter salary"
            value={employee.salary}
            onChange={handleInputChange}
            required
          />
          {errors.salary && <span className="error">{errors.salary}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            name="image"
            id="image"
            onChange={handleImageChange}
            accept="image/*"
            required
          />
          {imagePreview && <img src={imagePreview} alt="Image Preview" className="image-preview" />}
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Adding..." : "Add Employee"}
        </button>
      </form>
    </div>
  );
};

export default AddEmployee;
