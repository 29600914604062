// src/BeneficiaryDetails.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./BeneficiaryDetails.css"; // Import your CSS
import logoo from './pages/logo.png';
import sign from './sign.png';

const BeneficiaryDetails = () => {
  const { id } = useParams();
  const [beneficiary, setBeneficiary] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBeneficiary = async () => {
      try {
        const response = await axios.get(`https://megafamilyfoundation.org/api/beneficiaries/${id}`);
        setBeneficiary(response.data);
      } catch (error) {
        console.error("Error fetching beneficiary details:", error);
        setError("Failed to load beneficiary details. Please try again later.");
      }
    };
    fetchBeneficiary();
  }, [id]);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank', 'width=600,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Donation Receipt for ${beneficiary.name}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              color: #333;
            }
            h3 {
              text-align: center;
              color: black;
            }
            .receipt {
              border: 1px solid #ccc;
              padding: 20px;
              border-radius: 10px;
              max-width: 600px;
              margin: 0 auto;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            }
            .foundation-info {
              text-align: center;
              margin-bottom: 20px;
            }
            .foundation-info h2 {
              margin: 0;
              color: green;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #007BFF;
              color: black;
            }
            .quote {
              font-style: italic;
              margin: 20px 0;
              text-align: center;
              color: #555;
            }
          
            .print-button {
              background-color: #007BFF;
              color: white;
              padding: 10px 20px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              margin-top: 20px;
              display: block;
              text-align: center;
              width: 100%;
            }
              .footer { display: flex; justify-content: space-between; margin-top: 20px; font-size: 10px; }
              .signature { text-align: center; }
              .signature img { height: 50px; width: 70px; }
              .signatures p { margin-top: 60px; font-size: 10px; color: #333; }
          </style>
        </head>
        <body>
          <div class="receipt">
            <h3>Beneficiary Receipt</h3>
            <div class="foundation-info">
              <img src=${logoo} alt="Klin Kaara Foundation Logo" style="max-width: 100px; margin-bottom: 10px;"/>
              <h2>Klin Kaara Foundation</h2>
              <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999</p>
              <p>80G Registration No: AAKCK9026BE20241</p>
              <p>PAN No: AAKCK9026B</p>
              <p>TAN No: HYDK15890A</p>
            </div>
            <table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>${beneficiary.name}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>${beneficiary.phone}</td>
                </tr>
                <tr>
                  <th>Aadhar</th>
                  <td>${beneficiary.aadhar}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>${beneficiary.address}</td>
                </tr>
                <tr>
                  <th>Problem Description</th>
                  <td>${beneficiary.problemDescription}</td>
                </tr>
                <tr>
                  <th>Amount Received</th>
                  <td>₹${beneficiary.amountDonated}</td>
                </tr>
                <tr>
                  <th>Date of Donation</th>
                  <td>${new Date(beneficiary.dateOfDonation).toLocaleDateString()}</td>
                </tr>
              </tbody>
            </table>
            
            <div class="footer">
              <div class="signatures">
            
                <p>Beneficiary Signature</p>
              </div>
              <div class="signature">
                <img src=${sign} alt="Director Signature" />
                <p>Director Signature</p>
              </div>
              
            </div>
            <div class="quote">
              <p>"The best way to find yourself is to lose yourself in the service of others." – Mahatma Gandhi</p>
            </div>
              
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!beneficiary) {
    return <p>Loading beneficiary details...</p>;
  }

  const imagePath = beneficiary.image.replace(/\\/g, '/'); // Fixing image path

  return (
    <div className="beneficiary-details-container">
      <h2 className="beneficiary-title">{beneficiary.name}'s Details</h2>
      <div className="beneficiary-info">
        <div className="beneficiary-image">
          {beneficiary.image && <img src={`https://megafamilyfoundation.org/uploads/${imagePath}`} alt={beneficiary.name} />}
        </div>
        <div className="beneficiary-data">
          <p><strong>Name:</strong> {beneficiary.name}</p>
          <p><strong>Phone:</strong> {beneficiary.phone}</p>
          <p><strong>Aadhar:</strong> {beneficiary.aadhar}</p>
          <p><strong>Address:</strong> {beneficiary.address}</p>
          <p><strong>Problem Description:</strong> {beneficiary.problemDescription}</p>
          <p><strong>Amount Received:</strong> ₹{beneficiary.amountDonated}</p>
        </div>
      </div>
      <div className="quote">
        <p>"The best way to find yourself is to lose yourself in the service of others." – Mahatma Gandhi</p>
      </div>
      <button onClick={handlePrint} className="print-button">Print Receipt</button>
    </div>
  );
};

export default BeneficiaryDetails;
