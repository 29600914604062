// src/components/NRollExperienceLetter.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import './RollOfferLetter.css';

const NRollExperienceLetter = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [experienceDetails, setExperienceDetails] = useState({
    nirvinName: "",
    nirvinPosition: "",
    nirvinPhone: "",
    nirvinDateOfJoining: "",
    nirvinLeavingDate: "",
    nirvinDateOfIssuing: "",
    nirvinComments: "",
    nirvinEmployeeId: ""
  });
  const [loading, setLoading] = useState(false);
  const [experienceLetters, setExperienceLetters] = useState([]);

  // Fetch employees from the backend
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get("https://megafamilyfoundation.org/api/nirvin-employees");
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees", error);
      }
    };

    fetchEmployees();
  }, []);

  // Fetch existing experience letters from the backend
  useEffect(() => {
    const fetchExperienceLetters = async () => {
      try {
        const response = await axios.get("https://megafamilyfoundation.org/api/nirvin-experience-letters");
        setExperienceLetters(response.data);
      } catch (error) {
        console.error("Error fetching experience letters", error);
      }
    };

    fetchExperienceLetters();
  }, []);

  // Handle employee selection
  const handleEmployeeSelect = async (e) => {
    const id = e.target.value;
    setSelectedEmployeeId(id);
  
    if (id) {
      const employee = employees.find(emp => emp._id === id);
      if (employee) {
        const { nirvinName, nirvinPosition, nirvinPhone, nirvinDateOfJoining } = employee;

        setExperienceDetails({
          nirvinName,
          nirvinPosition,
          nirvinPhone,
          nirvinEmployeeId: nirvinPhone.slice(-4),
          nirvinDateOfJoining: new Date(nirvinDateOfJoining).toISOString().split("T")[0],
          nirvinLeavingDate: "",
          nirvinDateOfIssuing: new Date().toISOString().split("T")[0],
          nirvinComments: ""
        });
      }
    } else {
      setExperienceDetails({
        nirvinName: "",
        nirvinPosition: "",
        nirvinDateOfJoining: "",
        nirvinLeavingDate: "",
        nirvinDateOfIssuing: "",
        nirvinComments: "",
        nirvinPhone: "",
        nirvinEmployeeId: ""
      });
    }
  };

  // Handle input change for experience details
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExperienceDetails({ ...experienceDetails, [name]: value });
  };

  // Handle experience letter submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const experienceLetterData = {
      ...experienceDetails,
    };

    try {
      await axios.post("https://megafamilyfoundation.org/api/nirvin-experience-letters", experienceLetterData);
      alert("Experience letter generated successfully!");

      // Fetch updated experience letters list
      const response = await axios.get("https://megafamilyfoundation.org/api/nirvin-experience-letters");
      setExperienceLetters(response.data);

      // Resetting the form after submission
      setSelectedEmployeeId("");
      setExperienceDetails({
        nirvinName: "",
        nirvinPosition: "",
        nirvinPhone: "",
        nirvinDateOfJoining: "",
        nirvinLeavingDate: "",
        nirvinDateOfIssuing: "",
        nirvinComments: "",
        nirvinEmployeeId: ""
      });
    } catch (error) {
      console.error("Error saving the experience letter data!", error);
      alert("Failed to generate experience letter. Check console for details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="form-title">Roll Experience Letter</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Select Employee</label>
          <select value={selectedEmployeeId} onChange={handleEmployeeSelect} required>
            <option value="">Select an Employee</option>
            {employees.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.nirvinName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="nirvinName"
            value={experienceDetails.nirvinName}
            onChange={handleInputChange}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Position</label>
          <input
            type="text"
            name="nirvinPosition"
            value={experienceDetails.nirvinPosition}
            onChange={handleInputChange}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            name="nirvinPhone"
            value={experienceDetails.nirvinPhone}
            onChange={handleInputChange}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Date of Joining</label>
          <input
            type="date"
            name="nirvinDateOfJoining"
            value={experienceDetails.nirvinDateOfJoining}
            onChange={handleInputChange}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Leaving Date</label>
          <input
            type="date"
            name="nirvinLeavingDate"
            value={experienceDetails.nirvinLeavingDate}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Date of Issuing</label>
          <input
            type="date"
            name="nirvinDateOfIssuing"
            value={experienceDetails.nirvinDateOfIssuing}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Comments</label>
          <textarea
            name="nirvinComments"
            value={experienceDetails.nirvinComments}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Generating...' : 'Generate Experience Letter'}
        </button>
      </form>

      {/* <h3 className="letter-list-title">Existing Experience Letters</h3>
      <ul className="experience-letters-list">
        {experienceLetters.map((letter) => (
          <li key={letter._id}>
            {letter.nirvinName} - {letter.nirvinPosition}
            <button
              onClick={async () => {
                await axios.delete(`http://localhost:5000/api/nirvin-experience-letters/${letter._id}`);
                const updatedLetters = experienceLetters.filter(l => l._id !== letter._id);
                setExperienceLetters(updatedLetters);
              }}
              className="btn btn-danger"
            >
              Delete
            </button>
          </li>
        ))}
      </ul> */}
    </div>
  );
};

export default NRollExperienceLetter;
