import React, { useEffect, useState } from "react";
import axios from "axios";
import './ViewOfferLetters.css'; // Separate CSS for experience letter styles
import logoo from './pages/logo.png'; // Company logo
import sign from './sign.png'; // Director's signature image

const ViewExperienceLetters = () => {
  const [experienceLetters, setExperienceLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [searchId, setSearchId] = useState("");

  // Fetch experience letters on component mount
  useEffect(() => {
    const fetchExperienceLetters = async () => {
      try {
        const response = await axios.get("https://megafamilyfoundation.org/api/experienceletters");
        setExperienceLetters(response.data);
      } catch (error) {
        setError("Error fetching experience letters");
        console.error("Error fetching experience letters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExperienceLetters();
  }, []);

  const handleDelete = async (id) => {
    const passKey = "KKFRAJ88"; // This should ideally be handled more securely.
    const userInput = window.prompt("Please enter the pass key to confirm deletion:");
  
    if (userInput !== passKey) {
      alert("Invalid pass key. Deletion aborted.");
      return;
    }
  
    if (window.confirm("Are you sure you want to delete this experience letter?")) {
      try {
        const response = await axios.delete(`https://megafamilyfoundation.org/api/experienceletters/${id}`);
        alert(response.data.message);
        setExperienceLetters((prev) => prev.filter((letter) => letter._id !== id));
      } catch (error) {
        console.error("Error deleting experience letter:", error);
        alert("Failed to delete the experience letter.");
      }
    }
  };

  const handlePrint = (experienceLetter) => {
    const dateOfJoining = new Date(experienceLetter.dateOfJoining);
    const leavingDate = new Date(experienceLetter.leavingDate);
    
    // Calculate years and months of employment
    const totalMonths = (leavingDate.getFullYear() - dateOfJoining.getFullYear()) * 12 + (leavingDate.getMonth() - dateOfJoining.getMonth());
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
  
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Experience Letter</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 10px; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .experience-letter { 
                max-width: 700px; 
                margin: auto; 
                padding: 20px; 
                background: white; 
                border: none; 
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
                border-radius: 5px; 
                font-size: 16px; 
                line-height: 1.5; 
              }
              .header { 
                text-align: center; 
                margin-bottom: 20px; 
              }
              .header img { 
                width: 80px; 
                margin-bottom: 10px; 
              }
              .header h2 { 
                font-size: 24px; 
                margin: 5px 0; 
                color: #2C3E50; 
              }
              .header p { 
                font-size: 14px; 
                margin: 2px 0; 
                color: #34495E; 
              }
              .header h3 { 
                font-size: 22px; 
                margin: 15px 0; 
                color: #2980B9; 
                border-bottom: 2px solid #2980B9; 
                padding-bottom: 5px; 
              }
              .greeting { 
                margin: 20px 0; 
                font-size: 18px; 
                font-weight: bold; 
                color: #2C3E50; 
              }
              .content { 
                margin: 20px 0; 
                font-size: 16px; 
                color: #34495E; 
              }
              .footer { 
                display: flex; 
                justify-content: space-between; 
                margin-top: 30px; 
                font-size: 14px; 
                text-align: center; 
              }
              .signature-box img { 
                height: 60px; 
                width: auto; 
                margin-top: 20px; 
              }
              .signature-b p { 
                margin-top: 95px; 
                font-weight: bold; 
                text-decoration: underline; 
              }
              /* Ensure page fits on one print page */
              @media print {
                @page {
                  size: A4;
                  margin: 10mm; 
                }
              }
            }
          </style>
        </head>
        <body>
          <div class="experience-letter">
            <div class="header">
              <img src=${logoo} alt="Company Logo" />
              <h2>Klin Kaara Foundation</h2>
              <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: info@klinkaarafoundation.org</p>
              <p>CIN: U85500AP2024NPL114246</p>
              <h3>Experience Letter</h3>
            </div>
  
            <div class="greeting">
              Dear ${experienceLetter.name},
            </div>
            
            <div class="content">
              <p>This is to certify that you were employed at Klin Kaara Foundation as a <strong>${experienceLetter.position}</strong> from <strong>${dateOfJoining.toLocaleDateString()}</strong> to <strong>${leavingDate.toLocaleDateString()}</strong>.</p>
              
              <p>During your tenure of <strong>${years} year(s) ${months} month(s)</strong>, you consistently displayed remarkable dedication and professionalism in your role. Your contributions in <strong>${experienceLetter.position}</strong> were pivotal to our projects, and your ability to collaborate effectively with colleagues greatly enhanced our team's performance. You actively participated in various initiatives, showcasing your skills and commitment to our mission.</p>
  
              <p>At Klin Kaara Foundation, we strive to create an environment where everyone can grow and excel. Your efforts helped further our goal of making a meaningful impact in the community. We are truly grateful for your contributions and the positive attitude you brought to our team.</p>
  
              <p>As you move forward in your career, we wish you continued success in all your future endeavors. You will always be a valued member of the Klin Kaara Foundation family, and we encourage you to stay in touch.</p>
              
              <p>If you require any further information or assistance, please do not hesitate to reach out to us.</p>
            </div>
  
            <div class="footer">
              <div class="signature-b">
                <p><strong>Employee Signature</strong></p>
              </div>
              <div class="signature-box">
                <img src=${sign} alt="Director Signature" />
                <p><strong>Director Signature</strong></p>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  
  
  

  // Filter experience letters based on the search month, year, and ID
  const filteredExperienceLetters = experienceLetters.filter(letter => {
    const letterDate = new Date(letter.dateOfIssuing);

    const monthMatch = letterDate.toLocaleString('default', { month: 'long' }).toLowerCase().includes(searchMonth.toLowerCase());
    const yearMatch = letterDate.getFullYear().toString().includes(searchYear);
    const idMatch = letter.employeeId && letter.employeeId.includes(searchId);

    return monthMatch && yearMatch && idMatch;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="experience-letters-title">Experience Letters</h2>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by Employee ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by month name"
          value={searchMonth}
          onChange={(e) => setSearchMonth(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by year"
          value={searchYear}
          onChange={(e) => setSearchYear(e.target.value)}
        />
      </div>
      <table className="experience-letter-table">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Position</th>
            <th>Joining Date</th>
            <th>Leaving Date</th>
            <th>Date of Issuing</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredExperienceLetters.map((experienceLetter) => (
            <tr key={experienceLetter._id}>
              <td>{experienceLetter.phone ? experienceLetter.phone.toString().slice(-4) : "N/A"}</td>
              <td>{experienceLetter.name}</td>
              <td>{experienceLetter.position}</td>
              <td>{new Date(experienceLetter.dateOfJoining).toLocaleDateString()}</td>
              <td>{new Date(experienceLetter.leavingDate).toLocaleDateString()}</td>
              <td>{new Date(experienceLetter.dateOfIssuing).toLocaleDateString()}</td>
              <td>
                <button onClick={() => handlePrint(experienceLetter)}>Print</button>
                <button onClick={() => handleDelete(experienceLetter._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewExperienceLetters;
