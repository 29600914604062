import React from 'react';
import './HomePage.css';
import logo from './logo.jpg'; // Logo image for Nirvin Fertilizer and Pesticides Pvt Ltd

const NirvinHomePage = () => (
  <div className="home-container">
    <div className="overlay">
      <div className="content">
        <img src={logo} alt="Nirvin Fertilizer and Pesticides Logo" className="logo-img" />
        <h1 className="company-title">NIRVIN FERTILIZER AND PESTICIDES PVT LTD</h1>
        <p className="tagline">Empowering communities, transforming lives</p>
        <p className="contact-details">
          Address: 11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India<br />
          Contact: +91-6309193999<br />
        </p>
      </div>
    </div>
  </div>
);

export default NirvinHomePage;
