import React, { useEffect, useState } from "react";
import axios from "axios";
import logoo from "./pages/logo.png"; // Import the logo for printing
import "./receipt.css"; // Ensure styles are consistent
import sign from './sign.png';

const ReceiptList = ({ onBack }) => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [passkey, setPasskey] = useState(""); // State for passkey
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    fetchReceipts();
  }, []);

  const fetchReceipts = async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://megafamilyfoundation.org/api/receipts");
      setReceipts(response.data);
    } catch (error) {
      console.error("Error fetching receipts:", error);
      setError("Failed to fetch receipts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const deleteReceipt = async (id) => {
    const inputPasskey = prompt("Enter the passkey to delete this receipt:");
    if (inputPasskey !== "KKFRAJ88") { // Replace with your static passkey
      alert("Incorrect passkey. Deletion canceled.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this receipt?")) {
      try {
        const response = await axios.delete(`https://megafamilyfoundation.org/api/receipts/${id}`);
        if (response.status === 200) {
          fetchReceipts(); // Refresh the receipt list
          alert("Receipt deleted successfully!");
        }
      } catch (error) {
        console.error("Error deleting receipt:", error.response ? error.response.data : error.message);
        alert("Failed to delete receipt. Please try again.");
      }
    }
  };

  const printReceipt = (receipt) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              padding: 20px;
              background-color: #f9f9f9;
            }
            .logo {
              text-align: center;
              margin-bottom: 20px;
            }
            .receipt {
              padding: 20px;
              border: 1px solid #ccc;
              border-radius: 4px;
              background-color: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            h2, h3 {
              text-align: center;
              margin: 10px 0;
            }
            p {
              margin: 5px 0;
            }
            .info {
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            td {
              padding: 8px;
              border: 1px solid #ddd;
            }
            .footer {
              display: flex;
              justify-content: space-between;
              margin-top: 30px;
            }
            .signature-box {
              text-align: center;
              width: 45%;
            }
            .signature-b {
              margin-top:60px;
              color: black;
              text-align: center;
              width: 45%;
            }
            .signature-box img {
              height: 50px;
              width: 70px;
              margin-bottom: 5px;
            }
            .signature-box p {
              font-size: 0.9em;
              color: black;
              margin-top: 5px;
            }
            .thankYou {
              text-align: center;
              font-weight: bold;
              margin-top: 20px;
            }
          </style>
        </head>
        <body>
          <div class="logo">
            <img src="${logoo}" alt="Klin Kaara Foundation Logo" style="width: 80px; height: 80px;" />
          </div>
          <div class="receipt">
            <h2>Donation Receipt</h2>
            <p><strong>Receipt No:</strong> GD/${Math.floor(1000 + Math.random() * 9000)}/2024</p>
            <p><strong>Issued on:</strong> ${new Date().toLocaleDateString()}</p>
            <h3>Klin Kaara Foundation</h3>
            <p>All donations to Klin Kaara Foundation are exempt u/s 80G of Income Tax Act.</p>
            <div class="info">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Order ID:</strong></td>
                    <td>${receipt.orderid}</td>
                  </tr>
                  <tr>
                    <td><strong>Name:</strong></td>
                    <td>${receipt.name}</td>
                  </tr>
                  <tr>
                    <td><strong>PAN Of Donor:</strong></td>
                    <td>${receipt.pan}</td>
                  </tr>
                  <tr>
                    <td><strong>Email:</strong></td>
                    <td>${receipt.email}</td>
                  </tr>
                  <tr>
                    <td><strong>Amount:</strong></td>
                    <td>₹${receipt.amount}</td>
                  </tr>
                  <tr>
                    <td><strong>Date:</strong></td>
                    <td>${new Date(receipt.date).toLocaleDateString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p><strong>Received by:</strong></p>
            <p>Klin Kaara Foundation</p>
            <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
            <p>Phone: +91-6309193999</p>
            <p>80G Registration No: AAKCK9026BE20241</p>
            <p>PAN No: AAKCK9026B</p>
            <p>TAN No: HYDK15890A</p>
            
            <div class="footer">
              <div class="signature-b">
                <p>Donor Signature</p>
              </div>
              <div class="signature-box">
                <img src="${sign}" alt="Director Signature" />
                <p>Director Signature</p>
              </div>
            </div>
            <p class="thankYou">Thank you for your generous contribution!</p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  

  const printTable = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Receipts</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              padding: 20px;
              background-color: #f9f9f9;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              padding: 10px;
              border: 1px solid #ddd;
              text-align: left;
            }
            th {
              background-color: green;
              color: white;
            }
          </style>
        </head>
        <body>
          <h2>Receipt List</h2>
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Name</th>
                <th>PAN</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              ${receipts.map((receipt) => `
                <tr>
                  <td>${receipt.orderid}</td>
                  <td>${receipt.name}</td>
                  <td>${receipt.pan}</td>
                  <td>${receipt.email}</td>
                  <td>₹${receipt.amount}</td>
                  <td>${new Date(receipt.date).toLocaleDateString()}</td>
                </tr>
              `).join("")}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  // Filtered receipts based on the search term
  const filteredReceipts = receipts.filter((receipt) =>
    String(receipt.orderid).toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Receipt List</h1>
      <input
        type="text"
        placeholder="Search by Order ID..."
        style={styles.searchInput}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div style={styles.buttonContainer}>
        <button onClick={printTable} style={styles.button}>
          Print All Receipts
        </button>
        <button onClick={onBack} style={styles.button}>
          Back
        </button>
      </div>
      {loading ? (
        <p style={styles.loadingText}>Loading receipts...</p>
      ) : error ? (
        <p style={styles.errorText}>{error}</p>
      ) : (
        <ul style={styles.receiptList}>
          {filteredReceipts.map((receipt) => (
            <li key={receipt._id} style={styles.receiptItem}>
              <div style={styles.receiptDetails}>
                <p><strong>Order ID:</strong> {receipt.orderid}</p>
                <p><strong>Name:</strong> {receipt.name}</p>
                <p><strong>PAN:</strong> {receipt.pan}</p>
                <p><strong>Email:</strong> {receipt.email}</p>
                <p><strong>Amount:</strong> ₹{receipt.amount}</p>
                <p><strong>Date:</strong> {new Date(receipt.date).toLocaleDateString()}</p>
              </div>
              <div style={styles.buttons}>
                <button onClick={() => printReceipt(receipt)} style={styles.button}>
                  Print
                </button>
                <button onClick={() => deleteReceipt(receipt._id)} style={styles.button}>
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f3f4f6",
    borderRadius: "8px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    height: "100vh", // Full-screen height
    overflowY: "auto",
    fontFamily: "Arial, sans-serif",
  },
  heading: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "2em",
    color: "#333",
  },
  searchInput: {
    width: "100%",
    padding: "12px",
    marginBottom: "20px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1em",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  button: {
    padding: "10px",
    backgroundColor: "green", // Green color for primary action
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    flexGrow: 1,
    marginRight: "10px",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#45a049", // Darker shade for hover
  },
  secondaryButton: {
    padding: "10px",
    backgroundColor: "red", // Blue color for secondary action
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    flexGrow: 1,
    transition: "background-color 0.3s",
  },
  secondaryButtonHover: {
    backgroundColor: "#1976D2", // Darker shade for hover
  },
  loadingText: {
    textAlign: "center",
    fontSize: "1.2em",
    color: "#007BFF",
  },
  errorText: {
    textAlign: "center",
    fontSize: "1em",
    color: "red",
  },
  receiptList: {
    listStyleType: "none",
    padding: 0,
  },
  receiptItem: {
    padding: "15px",
    marginBottom: "15px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
  receiptDetails: {
    flexGrow: 1,
    marginRight: "10px",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
  },
  // New styles for table
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
  tableHeader: {
    backgroundColor: "green", // Blue color for table header
    color: "#fff",
    padding: "10px",
    textAlign: "left",
  },
  tableCell: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f1f1f1", // Light gray for hover effect on rows
    },
  },
};


export default ReceiptList;
