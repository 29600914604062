import React from 'react';
import './Navbar.css';
import logo from './logo.jpg'; // Company logo image
// import director1 from './director.jpg'; // Director 1's photo
// import director2 from './director.jpg'; // Director 2's photo

const NNavbar = () => {
    return (
        <nav className="nirvan-navbar">
            <div className="nirvan-navbar-logo">
                <img src={logo} alt="Company Logo" className="nirvan-logo-img" />
                <h1 className="nirvan-company-name">NIRVIN FERTILIZER AND
                PESTICIDES PVT LTD</h1>
                <span className="nirvan-admin-tag">Administration System</span>
            </div>
            <div className="nirvan-navbar-quote">
               <strong><p>Phone Number :
                    +91-6309193999</p>
                <p>Email Address :
                bgudivaka@gmail.com</p></strong>
            </div>
            <div className="nirvan-navbar-directors">
                {/* <div className="nirvan-director">
                    <img src={director1} alt="Director 1" className="nirvan-director-img" />
                    <p>Gudivaka Lakshmi</p>
                    <p>Director</p>
                </div> */}
                {/* <div className="nirvan-director">
                    <img src={director2} alt="Director 2" className="nirvan-director-img" />
                    <p>Director 2</p>
                </div> */}
            </div>
        </nav>
    );
};

export default NNavbar;
