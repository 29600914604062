import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import ReceiptGenerator from './ReceiptGenerator';
import ReceiptList from './ReceiptList';
import AddEmployee from './EmployeeManager'; 
import EmployeeList from './EmployeeList'; 
import EmployeeDetails from './EmployeeDetails';
import AddBeneficiary from './BeneficiaryManager';
import AddPayslip from './AddPayslip';
import Login from './Login';
import BeneficiaryList from './BeneficiaryList';
import BeneficiaryDetails from './BeneficiaryDetails';
import ViewPayslips from './ViewPayslips';
import RollOfferLetter from './RollOfferLetter';
import ViewOfferLetters from './ViewOfferLetters';
import RollTerminationLetter from './RollTerminationLetter';
import ViewTerminationLetters from './ViewTerminationLetters';
import RollExperienceLetter from './RollExperienceLetter';
import ViewExperienceLetters from './ViewExperienceLetters';
import Navbar from './Navbar'; // Navbar for Klin Kaara Foundation
import NNavbar from './nirvinpvtltd/Navbar'; // Navbar for Nirvin Fertilizer and Pesticides Pvt Ltd
import HomePage from './HomePage';
import IDCardGenerator from './IDCardGenerator';
import NHomePage from './nirvinpvtltd/HomePage'; // Home page for Nirvin
import PVDashboard from './nirvinpvtltd/NDashboard'; // Dashboard for Nirvin
import NAddEmployee from './nirvinpvtltd/EmployeeManager';
import NEmployeeList from './nirvinpvtltd/EmployeeList';
import NEmployeeDetails from './nirvinpvtltd/EmployeeDetails';
import NAddPayslip from './nirvinpvtltd/AddPayslip';
import NViewPayslips from './nirvinpvtltd/ViewPayslips';
import NRollOfferLetter from './nirvinpvtltd/RollOfferLetter';
import NViewOfferLetters from './nirvinpvtltd/ViewOfferLetters';
import NRollTerminationLetter from './nirvinpvtltd/RollTerminationLetter';
import NViewTerminationLetters from './nirvinpvtltd/ViewTerminationLetters';
import NRollExperienceLetter from './nirvinpvtltd/RollExperienceLetter';
import NViewExperienceLetters from './nirvinpvtltd/ViewExperienceLetters';
import NIDCardGenerator from './nirvinpvtltd/IDCardGenerator';

const App = () => {
  const [isAuthenticatedKlin, setIsAuthenticatedKlin] = useState(false);
  const [isAuthenticatedNirvin, setIsAuthenticatedNirvin] = useState(false);

  useEffect(() => {
    // Clear authentication on page load/refresh
    localStorage.removeItem("isAuthenticatedKlin");
    localStorage.removeItem("isAuthenticatedNirvin");
    setIsAuthenticatedKlin(false);
    setIsAuthenticatedNirvin(false);
  }, []);

  const handleKlinLogin = () => {
    localStorage.setItem("isAuthenticatedKlin", "true");
    setIsAuthenticatedKlin(true);
  };

  const handleNirvinLogin = () => {
    localStorage.setItem("isAuthenticatedNirvin", "true");
    setIsAuthenticatedNirvin(true);
  };

  const PrivateRouteKlin = ({ children }) => {
    return isAuthenticatedKlin ? children : <Navigate to="/" />;
  };

  const PrivateRouteNirvin = ({ children }) => {
    return isAuthenticatedNirvin ? children : <Navigate to="/" />;
  };

  return (
    <Router>
      {/* Conditionally render the appropriate Navbar based on authentication */}
      {isAuthenticatedKlin && <Navbar />}
      {isAuthenticatedNirvin && <NNavbar />}
      
      <Routes>
        <Route 
          path="/" 
          element={
            isAuthenticatedKlin || isAuthenticatedNirvin 
              ? <Navigate to={isAuthenticatedKlin ? "/dashboard/home" : "/nirvin-dashboard/home"} /> 
              : <Login onKlinLogin={handleKlinLogin} onNirvinLogin={handleNirvinLogin} />
          } 
        />

        {/* Routes for Klin Kaara Foundation */}
        <Route 
          path="/dashboard" 
          element={
            <PrivateRouteKlin>
              <Dashboard />
            </PrivateRouteKlin>
          }
        >
          <Route path="home" element={<HomePage />} />
          <Route path="generate-receipt" element={<ReceiptGenerator />} />
          <Route path="view-receipts" element={<ReceiptList />} />
          <Route path="add-employee" element={<AddEmployee />} />
          <Route path="view-employees" element={<EmployeeList />} />
          <Route path="employees/:id" element={<EmployeeDetails />} />
          <Route path="add-beneficiary" element={<AddBeneficiary mode="add" />} />
          <Route path="view-beneficiary" element={<BeneficiaryList mode='view' />} />
          <Route path="beneficiaries/:id" element={<BeneficiaryDetails />} />
          <Route path="generate-salary-slip" element={<AddPayslip />} />
          <Route path="view-salary-slips" element={<ViewPayslips />} />
          <Route path="roll-offer-letter" element={<RollOfferLetter />} />
          <Route path="view-offer-letters" element={<ViewOfferLetters />} />
          <Route path="roll-terminationletter" element={<RollTerminationLetter />} />
          <Route path="view-termination-letters" element={<ViewTerminationLetters />} />
          <Route path="roll-experience-letter" element={<RollExperienceLetter />} />
          <Route path="view-experience-letters" element={<ViewExperienceLetters />} />
          <Route path="roll-id-card" element={<IDCardGenerator />} />
        </Route>

        {/* Routes for Nirvin Fertilizer and Pesticides Pvt Ltd */}
        <Route 
          path="/nirvin-dashboard" 
          element={
            <PrivateRouteNirvin>
              <PVDashboard />
            </PrivateRouteNirvin>
          }
        >
          <Route path="home" element={<NHomePage />} />
          <Route path="add-employee" element={<NAddEmployee />} />
          <Route path="view-employees" element={<NEmployeeList />} />
          <Route path="nemployees/:id" element={<NEmployeeDetails />} />
       
          <Route path="generate-salary-slip" element={<NAddPayslip />} />
          <Route path="view-salary-slips" element={<NViewPayslips />} />
          <Route path="roll-offer-letter" element={<NRollOfferLetter />} />
          <Route path="view-offer-letters" element={<NViewOfferLetters />} />
          <Route path="roll-terminationletter" element={<NRollTerminationLetter />} />
          <Route path="view-termination-letters" element={<NViewTerminationLetters />} />
          <Route path="roll-experience-letter" element={<NRollExperienceLetter />} />
          <Route path="view-experience-letters" element={<NViewExperienceLetters />} />
          <Route path="generate-id-card" element={<NIDCardGenerator />} />
          
          {/* Add other Nirvin routes here as needed */}
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
