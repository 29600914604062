import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./EmployeeDetails.css"; // Import the CSS file
import logoo from './logo.png';
import sign from './sign.png';

const NEmployeeDetails = () => {
  const { id } = useParams(); // Get the employee ID from the URL
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`https://megafamilyfoundation.org/api/nirvin-employees/${id}`); // Adjusted endpoint to match the backend
        setEmployee(response.data);
      } catch (error) {
        console.error("Error fetching employee details:", error);
      }
    };
    fetchEmployee();
  }, [id]);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>${employee.nirvinName}'s Professional Resume</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              color: #333;
              padding: 20px;
              margin: 0;
              width: 100%;
              box-sizing: border-box;
            }
            .header, .footer {
              text-align: center;
              margin-bottom: 10px;
            }
            .logo {
              width: 60px;
              height: 60px;
              margin-bottom: 5px;
            }
            h2 {
              color: green;
              margin: 0;
            }
            .header p, .footer p {
              font-size: 0.95em;
              color: #555;
              margin: 2px 0;
            }
            .section-box {
              border: 1px solid #ddd;
              padding: 10px;
              margin-bottom: 10px;
              border-radius: 5px;
            }
            .table-title {
              font-size: 1.1em;
              color: #007BFF;
              margin-bottom: 8px;
              font-weight: bold;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 8px;
            }
            th, td {
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
            }
            th {
              background-color: #007BFF;
              color: #fff;
            }
            .signatures {
              display: flex;
              justify-content: space-between;
              margin-top: 40px;
              font-size: 0.95em;
            }
            .signature-b {
              margin-top: 45px;
            }
            .signature-box {
              text-align: center;
            }
            .signature-box img {
              height: 40px;
              width: 70px;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <img src="${logoo}" alt="Klin Kaara Foundation Logo" class="logo" />
            <h2>Klin Kaara Foundation</h2>
            <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
            <p>Phone: +91-6309193999 | Email: info@klinkaarafoundation.org</p>
            <p>80G Reg: AAKCK9026BE20241 | PAN: AAKCK9026B | TAN: HYDK15890A</p>
          </div>
  
          <div class="section-box">
            <div class="table-title">Employee Details</div>
            <table>
              <tr>
                <th>Field</th>
                <th>Information</th>
              </tr>
              <tr>
                <td>Name</td>
                <td>${employee.nirvinName}</td>
              </tr>
              <tr>
                <td>Employee ID</td>
                <td>KKF${employee.nirvinPhone ? employee.nirvinPhone.slice(-4) : "N/A"}</td>
              </tr>
              <tr>
                <td>Date of Joining</td>
                <td>${new Date(employee.nirvinDateOfJoining).toLocaleDateString()}</td> {/* Format date */}
              </tr>
              <tr>
                <td>Position</td>
                <td>${employee.nirvinPosition}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>${employee.nirvinEmail}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>${employee.nirvinPhone}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>${employee.nirvinAddress}</td>
              </tr>
            </table>
          </div>
  
          <div class="section-box">
            <div class="table-title">Financial Details</div>
            <table>
              <tr>
                <th>Field</th>
                <th>Information</th>
              </tr>
              <tr>
                <td>A/C No</td>
                <td>${employee.nirvinAccountNumber}</td>
              </tr>
              <tr>
                <td>Bank Name</td>
                <td>${employee.nirvinBankName}</td>
              </tr>
              <tr>
                <td>PAN</td>
                <td>${employee.nirvinPan}</td>
              </tr>
              <tr>
                <td>Aadhar</td>
                <td>${employee.nirvinAadhar}</td>
              </tr>
              <tr>
                <td>PF Number</td>
                <td>${employee.nirvinPfNumber}</td>
              </tr>
              <tr>
                <td>ESI Number</td>
                <td>${employee.nirvinEsiNumber}</td>
              </tr>
              <tr>
                <td>Salary</td>
                <td>₹${employee.nirvinSalary}</td>
              </tr>
            </table>
          </div>
  
          <div class="signatures">
            <div class="signature-b">
              <p>Employee Signature</p>
            </div>
            <div class="signature-box">
              <img src="${sign}" alt="Director Signature" />
              <p>Director Signature</p>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  if (!employee) {
    return <p>Loading employee details...</p>;
  }

  return (
    <div className="employee-details-container">
      <h2 className="employee-title">{employee.nirvinName}'s Details</h2>
      <div className="employee-info">
        <div className="employee-image">
          {employee.nirvinImage && <img src={`https://megafamilyfoundation.org/uploads/${employee.nirvinImage}`} alt={employee.nirvinName} />}
        </div>
        <div className="employee-data">
          <p><strong>Name:</strong> {employee.nirvinName}</p>
          <p><strong>Position:</strong> {employee.nirvinPosition}</p>
          <p><strong>Email:</strong> {employee.nirvinEmail}</p>
          <p><strong>Phone:</strong> {employee.nirvinPhone}</p>
          <p><strong>Address:</strong> {employee.nirvinAddress}</p>
          <p><strong>Date of Joining:</strong> {new Date(employee.nirvinDateOfJoining).toLocaleDateString()}</p>
          <p><strong>A/C No:</strong> {employee.nirvinAccountNumber}</p>
          <p><strong>Bank Name:</strong> {employee.nirvinBankName}</p>
          <p><strong>PAN:</strong> {employee.nirvinPan}</p>
          <p><strong>Aadhar:</strong> {employee.nirvinAadhar}</p>
          <p><strong>PF Number:</strong> {employee.nirvinPfNumber}</p>
          <p><strong>ESI Number:</strong> {employee.nirvinEsiNumber}</p>
          <p><strong>Salary:</strong> ₹{employee.nirvinSalary}</p>
        </div>
      </div>
      <button onClick={handlePrint} className="print-button">Print</button>
    </div>
  );
};

export default NEmployeeDetails;
