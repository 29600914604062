import React from 'react';
import './HomePage.css';
import logo from './logo.png'; // Logo image for Klin Kaara Foundation

const HomePage = () => (
 
  <div className="home-page">
    <div className="overlay">
      <div className="content">
        <img src={logo} alt="Klin Kaara Foundation Logo" className="logo" />
        <h1 className="foundation-name">Klin Kaara Foundation</h1>
        <p className="foundation-taglines">Empowering communities, transforming lives</p>
        <p className="foundation-detailss">
          Address: 11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India<br />
          Contact: +91-6309193999<br />
          Registration No: AAKCK9026BE20241
        </p>
        
      </div>
      
    </div>
    
  </div>
 
  
);

export default HomePage;
