import React, { useState, useEffect } from "react";
import axios from "axios";
// import './RollExperienceLetter.css'; // Optional: add a separate CSS file for styling

const RollExperienceLetter = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [employee, setEmployee] = useState({});
  const [experienceDetails, setExperienceDetails] = useState({
    name: "",
    position: "",
    dateOfJoining: "",
    leavingDate: "",
    dateOfIssuing: "", // Manage dateOfIssuing
    comments: "",
    phone: "",
    employeeId: ""
  });
  const [loading, setLoading] = useState(false);
  const [experienceLetters, setExperienceLetters] = useState([]);

  // Fetch employees from the backend
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get("https://megafamilyfoundation.org/api/employees");
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees", error);
      }
    };

    fetchEmployees();
  }, []);

  // Fetch existing experience letters from the backend
  useEffect(() => {
    const fetchExperienceLetters = async () => {
      try {
        const response = await axios.get("https://megafamilyfoundation.org/api/experienceletters");
        setExperienceLetters(response.data);
      } catch (error) {
        console.error("Error fetching experience letters", error);
      }
    };

    fetchExperienceLetters();
  }, []);

  // Handle employee selection
  const handleEmployeeSelect = async (e) => {
    const id = e.target.value;
    setSelectedEmployeeId(id);

    if (id) {
      try {
        const response = await axios.get(`https://megafamilyfoundation.org/api/employees/${id}`);
        const { name, position, phone, dateOfJoining } = response.data;

        // Set experience details and generate employee ID from the last four digits of phone
        setEmployee(response.data);
        setExperienceDetails((prev) => ({
          ...prev,
          name,
          position,
          phone,
          employeeId: phone.slice(-4), // Generate employeeId from the last four digits of phone
          dateOfJoining, // Fetch joining date from employee data
          dateOfIssuing: new Date().toISOString().split("T")[0] // Set the current date for dateOfIssuing
        }));
      } catch (error) {
        console.error("Error fetching employee details", error);
      }
    } else {
      setEmployee({});
      setExperienceDetails({
        name: "",
        position: "",
        dateOfJoining: "",
        leavingDate: "",
        dateOfIssuing: "", // Reset dateOfIssuing
        comments: "",
        phone: "",
        employeeId: ""
      });
    }
  };

  // Handle input change for experience details
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExperienceDetails({ ...experienceDetails, [name]: value });
  };

  // Handle experience letter submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { name, position, phone, dateOfJoining, leavingDate, comments, employeeId, dateOfIssuing } = experienceDetails;

    const experienceLetterData = {
      employeeId: employeeId, // Keep the generated employeeId
      name,
      position,
      phone,
      dateOfJoining,
      leavingDate,
      dateOfIssuing, // Include dateOfIssuing in the data sent to the backend
      comments,
    };

    try {
      await axios.post("https://megafamilyfoundation.org/api/experienceletters", experienceLetterData);
      alert("Experience letter generated successfully!");

      // Fetch updated experience letters list
      const response = await axios.get("https://megafamilyfoundation.org/api/experienceletters");
      setExperienceLetters(response.data);

      // Resetting the form after submission
      setSelectedEmployeeId("");
      setEmployee({});
      setExperienceDetails({
        name: "",
        position: "",
        phone: "",
        dateOfJoining: "",
        leavingDate: "",
        dateOfIssuing: "", // Reset dateOfIssuing
        comments: "",
        employeeId: ""
      });
    } catch (error) {
      console.error("Error saving the experience letter data!", error.response?.data || error);
      alert("Failed to generate experience letter. Check console for details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="form-title">Roll Experience Letter</h2>
      <form onSubmit={handleSubmit} className="experience-letter-form">

        {/* Employee Selection Dropdown */}
        <div className="form-group">
          <label htmlFor="employeeSelect">Select Employee</label>
          <select
            id="employeeSelect"
            value={selectedEmployeeId}
            onChange={handleEmployeeSelect}
            required
          >
            <option value="">--Select Employee--</option>
            {employees.map((emp) => (
              <option key={emp._id} value={emp._id}>
                {emp.name}
              </option>
            ))}
          </select>
        </div>

        {/* Experience Letter Details */}
        <div className="form-group">
          <label>Name</label>
          <input type="text" value={experienceDetails.name} readOnly />
        </div>
        <div className="form-group">
          <label>Position</label>
          <input type="text" value={experienceDetails.position} readOnly />
        </div>
        <div className="form-group">
          <label>Mobile Number</label>
          <input type="text" value={experienceDetails.phone} readOnly />
        </div>
        <div className="form-group">
          <label>Employee ID</label>
          <input type="text" value={experienceDetails.employeeId} readOnly />
        </div>
        <div className="form-group">
          <label>Joining Date</label>
          <input
            type="date"
            name="joiningDate"
            value={experienceDetails.dateOfJoining}
            onChange={handleInputChange}
            required
            readOnly // Optional: If you want to prevent editing, keep it readOnly
          />
        </div>
        <div className="form-group">
          <label>Leaving Date</label>
          <input
            type="date"
            name="leavingDate"
            value={experienceDetails.leavingDate}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Date of Issuing</label>
          <input
            type="date"
            name="dateOfIssuing"
            value={experienceDetails.dateOfIssuing}
            readOnly
          />
        </div>
        <div className="form-group">
          <label>Additional Comments</label>
          <textarea
            name="comments"
            placeholder="Enter any additional comments"
            value={experienceDetails.comments}
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Generate Experience Letter"}
        </button>
      </form>

      {/* Optional: Display a list of existing experience letters */}
      {/* <ul className="experience-letters-list">
        {experienceLetters.map((letter) => (
          <li key={letter._id}>
            <p><strong>Employee:</strong> {letter.name} - {letter.position}</p>
            <p><strong>Joining Date:</strong> {new Date(letter.joiningDate).toLocaleDateString()}</p>
            <p><strong>Leaving Date:</strong> {new Date(letter.leavingDate).toLocaleDateString()}</p>
            <p><strong>Comments:</strong> {letter.comments}</p>
            <button onClick={async () => {
              await axios.delete(`http://localhost:5000/api/experienceletters/${letter._id}`);
              setExperienceLetters(experienceLetters.filter(l => l._id !== letter._id));
            }}>
              Delete
            </button>
          </li>
        ))}
      </ul> */}
    </div>
  );
};

export default RollExperienceLetter;
