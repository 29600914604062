import React, { useState } from 'react';
import axios from 'axios';
import logoo from './pages/logo.png';
import sign from './sign.png';
import './App.css';

const ReceiptGenerator = ({ onBack }) => {
  const [donation, setDonation] = useState({
    name: '',
    email: '',
    amount: '',
    date: '',
    pan: '',
    orderid: '',
    donorSignature: '',
  });

  const handleChange = (e) => {
    setDonation({ ...donation, [e.target.name]: e.target.value });
  };

  const handleSignatureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDonation({ ...donation, donorSignature: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const generateReceipt = async () => {
    // Validate form fields
    if (!donation.name || !donation.email || !donation.amount || !donation.date || !donation.pan) {
        alert('Please fill in all the required fields.');
        return;
    }

    // Generate a random order ID
    const randomOrderId = Math.floor(1000 + Math.random() * 9000).toString();

    // Prepare receipt data with the random order ID
    const receiptData = {
        ...donation,
        orderid: randomOrderId,
    };

    try {
        // Store receipt details on the server
        await axios.post('https://megafamilyfoundation.org/api/receipts', receiptData);
        alert('Receipt generated and stored successfully!');

        // Wait for the state update to complete before printing
        printReceipt(receiptData); // Pass the receiptData directly to print
    } catch (error) {
        console.error('Error generating receipt:', error);
    }
};

const printReceipt = (receipt) => {
  const printWindow = window.open("", "_blank");
  printWindow.document.write(`
    <html>
      <head>
        <title>Receipt</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
            padding: 20px;
            background-color: #f9f9f9;
          }
          .logo {
            text-align: center;
            margin-bottom: 20px;
          }
          .receipt {
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          h2, h3 {
            text-align: center;
            margin: 10px 0;
          }
          p {
            margin: 5px 0;
          }
          .info {
            margin-bottom: 20px;
          }
          .thankYou {
            text-align: center;
            font-weight: bold;
            margin-top: 20px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
          }
          td {
            padding: 8px;
            border: 1px solid #ddd;
          }
          .footer {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
          }
          .signature-box {
            text-align: center;
            width: 45%;
          }

          .signature-b {
          margin-top:60px;
          color: black;

            text-align: center;
            width: 45%;
          }
          .signature-box img {
            height: 50px;
            width: 70px;
            margin-bottom: 5px;
          }
          .signature-box p {
            font-size: 0.9em;
            color: black;
            margin-top: 5px;
          }
        </style>
      </head>
      <body>
        <div class="logo">
          <img src="${logoo}" alt="Klin Kaara Foundation Logo" style="width: 80px; height: 80px;" />
        </div>
        <div class="receipt">
          <h2>Donation Receipt</h2>
          <p><strong>Receipt No:</strong> GD/${Math.floor(1000 + Math.random() * 9000)}/2024</p>
          <p><strong>Issued on:</strong> ${new Date().toLocaleDateString()}</p>
          <h3>Klin Kaara Foundation</h3>
          <p>All donations to Klin Kaara Foundation are exempt u/s 80G of Income Tax Act.</p>
          <div class="info">
            <table>
              <tbody>
                <tr>
                  <td><strong>Order ID:</strong></td>
                  <td>${receipt.orderid}</td>
                </tr>
                <tr>
                  <td><strong>Name:</strong></td>
                  <td>${receipt.name}</td>
                </tr>
                <tr>
                  <td><strong>PAN Of Donor:</strong></td>
                  <td>${receipt.pan}</td>
                </tr>
                <tr>
                  <td><strong>Email:</strong></td>
                  <td>${receipt.email}</td>
                </tr>
                <tr>
                  <td><strong>Amount:</strong></td>
                  <td>₹${receipt.amount}</td>
                </tr>
                <tr>
                  <td><strong>Date:</strong></td>
                  <td>${new Date(receipt.date).toLocaleDateString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p><strong>Received by:</strong></p>
          <p>Klin Kaara Foundation</p>
          <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
          <p>Phone: +91-6309193999</p>
          <p>80G Registration No: AAKCK9026BE20241</p>
          <p>PAN No: AAKCK9026B</p>
          <p>TAN No: HYDK15890A</p>
          
          <div class="footer">
            <div class="signature-b">
              <p>Donor Signature</p>
            </div>
            <div class="signature-box">
              <img src="${sign}" alt="Director Signature" />
              <p>Director Signature</p>
            </div>
          </div>
          <p class="thankYou">Thank you for your generous contribution!</p>
        </div>
      </body>
    </html>
  `);
  printWindow.document.close();
  printWindow.print();
};



  return (
    <div>
      <div id="receipt" style={styles.receipt}>
        <div style={styles.container}>
          <div style={styles.logoContainer}>
            <img 
              src={logoo} 
              alt="Klin Kaara Foundation Logo" 
              style={styles.logo} 
            />
          </div>
          <h2 style={styles.heading}>Donation Receipt</h2>

          <p><strong>Receipt No:</strong> GD/{Math.floor(1000 + Math.random() * 9000)}/2024</p>
          <p><strong>Issued on:</strong> {new Date().toLocaleDateString()}</p>
          <h3>Klin Kaara Foundation</h3>
          <p>All donations to Klin Kaara Foundation are exempt u/s 80G of Income Tax Act.</p>
          <table style={styles.table}>
            <tbody>
              <tr>
                <td><strong>Order ID:</strong></td>
                <td>{donation.orderid}</td>
              </tr>
              <tr>
                <td><strong>Date of Donation:</strong></td>
                <td>{donation.date}</td>
              </tr>
              <tr>
                <td><strong>Name of Donor:</strong></td>
                <td>{donation.name}</td>
              </tr>
              <tr>
                <td><strong>PAN of Donor:</strong></td>
                <td>{donation.pan}</td>
              </tr>
              <tr>
                <td><strong>Amount Donated:</strong></td>
                <td>₹{donation.amount}</td>
              </tr>
            </tbody>
          </table>

          <p><strong>Received by:</strong></p>
          <p>Klin Kaara Foundation</p>
          <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
          <p>Phone: +91-6309193999</p>
          <p>80G Registration No: AAKCK9026BE20241</p>
          <p>PAN No: AAKCK9026B</p>
          <p>TAN No: HYDK15890A</p>

          <div style={styles.signatureContainer}>
            <div style={styles.signatureBox}>
              <p><strong>Donor Signature:</strong></p>
            </div>
            <div style={styles.signatureBox}>
              <p><strong>Receiver Signature:</strong></p>
            </div>
          </div>

          <p style={styles.thankYou}>
            Thank you for your generous contribution! Your support helps us continue our mission.
          </p>
        </div>
      </div>

      <input 
        type="text" 
        name="name" 
        placeholder="Name" 
        value={donation.name} 
        onChange={handleChange} 
        style={styles.input}
        required
      />
      <input 
        type="email" 
        name="email" 
        placeholder="Email" 
        value={donation.email} 
        onChange={handleChange} 
        style={styles.input}
        required
      />
      <input 
        type="text" 
        name="pan" 
        placeholder="PAN" 
        value={donation.pan} 
        onChange={handleChange} 
        style={styles.input}
        required
      />
      <input 
        type="number" 
        name="amount" 
        placeholder="Amount" 
        value={donation.amount} 
        onChange={handleChange} 
        style={styles.input}
        required
      />
      <input 
        type="date" 
        name="date" 
        value={donation.date} 
        onChange={handleChange} 
        style={styles.input}
        required
      />

      <button onClick={generateReceipt} style={styles.button}>Generate and Print Receipt</button>
     

      <footer style={styles.footer}>
        {/* Footer content can go here */}
      </footer>
    </div>
  );
};

const styles = {
  container: {
    padding: '30px', // Increased padding for more space
    borderRadius: '10px',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    maxWidth: '600px',
    margin: 'auto',
    backgroundColor: '#ffffff', // Maintain a clean background
  },
  heading: {
    textAlign: 'center',
    marginBottom: '30px', // More space for a clear title
    fontSize: '24px', // Larger font size for the heading
    fontWeight: 'bold',
    color: '#333', // Darker text for readability
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: '100px', // Slightly larger logo for visibility
    height: '100px',
  },
  receipt: {
    marginBottom: '20px',
    padding: '20px',
    border: '1px solid #eaeaea', // Lighter border for a softer look
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    fontSize: '16px', // Slightly larger font for readability
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '12px', // Increased padding for comfort
    marginBottom: '15px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '16px', // Increase font size for readability
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Soft shadow for input fields
    transition: 'border-color 0.3s', // Transition effect on focus
  },
  inputFocus: {
    borderColor: '#007BFF', // Change border color on focus
    outline: 'none', // Remove default outline
  },
  button: {
    width: '100%',
    padding: '12px',
    marginBottom: '10px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007BFF',
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '16px', // Larger button text
    transition: 'background-color 0.3s, transform 0.2s', // Transition for hover effects
  },
  buttonHover: {
    backgroundColor: '#0056b3', // Darker blue on hover
    transform: 'scale(1.02)', // Slight scale effect on hover
  },
  signatureContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  signatureBox: {
    width: '48%',
    border: '1px dashed #ccc', // Dashed border for signature areas
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px', // Font size for signature text
    color: '#666', // Softer color for signature labels
  },
  thankYou: {
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: '20px',
    fontSize: '14px',
    color: '#333',
  },
  footer: {
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '12px',
    color: '#888', // Softer color for footer text
  },
};


export default ReceiptGenerator;
