import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Change from useHistory to useNavigate
import './Login.css'; // Add your CSS for styling

const Login = ({ onKlinLogin, onNirvinLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isNirvin, setIsNirvin] = useState(false); // State to toggle between companies
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isNirvin) {
      if (username === 'RAJ' && password === 'NFP@RAJ99') {
        onNirvinLogin(); // Call Nirvin login handler
      } else {
        setErrorMessage('Invalid Nirvin credentials');
      }
    } else {
      if (username === 'RAJ' && password === 'KKF@RAJ88') {
        onKlinLogin(); // Call Klin Kaara login handler
      } else {
        setErrorMessage('Invalid Klin Kaara credentials');
      }
    }
  };
  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>{isNirvin ? 'NIRVIN FERTILIZER AND PESTICIDES PRIVATE LIMITED' : 'KLIN KAARA FOUNDATION'}</h2>
        <label>
          <input
            type="checkbox"
            checked={isNirvin}
            onChange={() => {
              setIsNirvin(!isNirvin);
              setUsername('');
              setPassword('');
              setErrorMessage('');
            }}
          />
          Login as Nirvin Fertilizer
        </label>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
