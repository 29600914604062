import React from 'react';
import './Navbar.css';
import logo from './logo.png'; // Company logo image
// import director1 from './director.jpg'; // Director 1's photo
// import director2 from './director.jpg'; // Director 2's photo

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={logo} alt="Company Logo" className="logo-imgg" />
                <h1 className="company-name">Klin Kaara Foundation</h1>
                <span className="admin-tag">Administration System</span>
            </div>
            <div className="navbar-quote">
               <strong><p>Phone Number :
                    +91-6309193999</p>
                <p>Email Address
                    info@klinkaarafoundation.org</p></strong>
                
            </div>
            <div className="navbar-directors">
                {/* <div className="director">
          <img src={director1} alt="Director 1" className="director-img" />
          <p>Gudivaka Lakshmi</p>
          <p>Director</p>
        </div> */}
                {/* <div className="director">
          <img src={director2} alt="Director 2" className="director-img" />
          <p>Director 2</p>
        </div> */}
            </div>
        </nav>
    );
};

export default Navbar;
