import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NAddPayslip = () => {
  const [formData, setFormData] = useState({
    nirvinEmployeeId: '',
    nirvinEmpid: '',
    nirvinName: '',
    nirvinDateOfJoining: '',
    nirvinPosition: '',
    nirvinPhone: '',
    nirvinEmail: '',
    nirvinAccountNumber: '',
    nirvinBankName: '',
    nirvinPan: '',
    nirvinAadhar: '',
    nirvinSalary: '',
    nirvinBasicSalary: '',
    nirvinHra: '',
    nirvinTransportAllowance: '',
    nirvinPf: '',
    nirvinEsiNumber: '',
    nirvinTax: '',
    nirvinNetSalary: '',
    nirvinGrossSalary: '',
    nirvinWorkingDays: '',
    nirvinPresentDays: '',
    nirvinPfAmount: '',
    nirvinIncentives: '',
    nirvinDateOfIssuing: '',
  });

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://megafamilyfoundation.org/api/nirvin-employees');
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Automatically calculate Net Salary and Gross Salary
      calculateSalaries(updatedData);
      return updatedData;
    });
  };

  const handleEmployeeSelect = async (e) => {
    const selectedEmployeeId = e.target.value;
    const selectedEmployee = employees.find(emp => emp._id === selectedEmployeeId);

    if (selectedEmployee) {
      const mobile = selectedEmployee.nirvinPhone;
      const empId = mobile.slice(-4); // Get the last 4 digits of mobile number

      setFormData((prevData) => {
        const updatedData = {
          ...prevData,
          nirvinEmployeeId: selectedEmployeeId,
          nirvinEmpid: empId, // Automatically set employee ID
          nirvinName: selectedEmployee.nirvinName || '',
          nirvinDateOfJoining: selectedEmployee.nirvinDateOfJoining || '', // Automatically set date of joining
          nirvinPosition: selectedEmployee.nirvinPosition || '',
          nirvinPhone: selectedEmployee.nirvinPhone || '',
          nirvinEmail: selectedEmployee.nirvinEmail || '',
          nirvinAccountNumber: selectedEmployee.nirvinAccountNumber || '',
          nirvinBankName: selectedEmployee.nirvinBankName || '',
          nirvinPan: selectedEmployee.nirvinPan || '',
          nirvinAadhar: selectedEmployee.nirvinAadhar || '',
          nirvinSalary: selectedEmployee.nirvinSalary || '',
          // Set any additional fields you want to auto-fill
        };

        // Automatically calculate Net Salary and Gross Salary
        calculateSalaries(updatedData);
        return updatedData;
      });
    } else {
      setFormData({
        ...formData,
        nirvinEmployeeId: '',
        nirvinEmpid: '',
        nirvinName: '',
        nirvinDateOfJoining: '',
        nirvinPosition: '',
        nirvinPhone: '',
        nirvinEmail: '',
        nirvinAccountNumber: '',
        nirvinBankName: '',
        nirvinPan: '',
        nirvinAadhar: '',
        nirvinSalary: '',
        nirvinBasicSalary: '',
        nirvinHra: '',
        nirvinTransportAllowance: '',
        nirvinPf: '',
        nirvinEsiNumber: '',
        nirvinTax: '',
        nirvinNetSalary: '',
        nirvinGrossSalary: '',
        nirvinWorkingDays: '',
        nirvinPresentDays: '',
        nirvinPfAmount: '',
        nirvinIncentives: '',
        nirvinDateOfIssuing: '',
      });
    }
  };

  const calculateSalaries = (data) => {
    const basicSalary = parseFloat(data.nirvinBasicSalary) || 0;
    const hra = parseFloat(data.nirvinHra) || 0;
    const transportAllowance = parseFloat(data.nirvinTransportAllowance) || 0;
    const incentives = parseFloat(data.nirvinIncentives) || 0;

    const grossSalary = basicSalary + hra + transportAllowance + incentives;

    const pf = parseFloat(data.nirvinPfAmount) || 0;
    const tax = parseFloat(data.nirvinTax) || 0;

    const netSalary = grossSalary - pf - tax;

    setFormData(prevData => ({
      ...prevData,
      nirvinGrossSalary: grossSalary,
      nirvinNetSalary: netSalary,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://megafamilyfoundation.org/api/nirvin-payslips', formData);
      setFormData({
        nirvinEmployeeId: '',
        nirvinEmpid: '',
        nirvinName: '',
        nirvinDateOfJoining: '',
        nirvinPosition: '',
        nirvinPhone: '',
        nirvinEmail: '',
        nirvinAccountNumber: '',
        nirvinBankName: '',
        nirvinPan: '',
        nirvinAadhar: '',
        nirvinSalary: '',
        nirvinBasicSalary: '',
        nirvinHra: '',
        nirvinTransportAllowance: '',
        nirvinPf: '',
        nirvinEsiNumber: '',
        nirvinTax: '',
        nirvinNetSalary: '',
        nirvinGrossSalary: '',
        nirvinWorkingDays: '',
        nirvinPresentDays: '',
        nirvinPfAmount: '',
        nirvinIncentives: '',
        nirvinDateOfIssuing: '',
      });
      alert("Payslip added successfully!");
    } catch (error) {
      console.error("Error adding payslip:", error);
      alert("Failed to add payslip. Please try again.");
    }
  };

  return (
    <div className="add-payslip-container">
      <h2>Add Payslip</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Employee</label>
          <select
            name="nirvinEmployeeId"
            value={formData.nirvinEmployeeId}
            onChange={handleEmployeeSelect}
            required
          >
            <option value="">Select Employee</option>
            {employees.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.nirvinName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label> Emp ID</label>
          <input
            type="text"
            name="nirvinEmpid"
            value={formData.nirvinEmpid}
            readOnly // Make it read-only since it's calculated
          />
        </div>

        <div>
          <label>Name</label>
          <input
            type="text"
            name="nirvinName"
            value={formData.nirvinName}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Date of Joining</label>
          <input
            type="date"
            name="nirvinDateOfJoining"
            value={formData.nirvinDateOfJoining}
            readOnly // Make it read-only since it's populated automatically
          />
        </div>

        <div>
          <label>Position</label>
          <input
            type="text"
            name="nirvinPosition"
            value={formData.nirvinPosition}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label> Phone</label>
          <input
            type="text"
            name="nirvinPhone"
            value={formData.nirvinPhone}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Email</label>
          <input
            type="email"
            name="nirvinEmail"
            value={formData.nirvinEmail}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Account Number</label>
          <input
            type="text"
            name="nirvinAccountNumber"
            value={formData.nirvinAccountNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Bank Name</label>
          <input
            type="text"
            name="nirvinBankName"
            value={formData.nirvinBankName}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>PAN</label>
          <input
            type="text"
            name="nirvinPan"
            value={formData.nirvinPan}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Aadhar</label>
          <input
            type="text"
            name="nirvinAadhar"
            value={formData.nirvinAadhar}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Basic Salary</label>
          <input
            type="number"
            name="nirvinBasicSalary"
            value={formData.nirvinBasicSalary}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label> HRA</label>
          <input
            type="number"
            name="nirvinHra"
            value={formData.nirvinHra}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Transport Allowance</label>
          <input
            type="number"
            name="nirvinTransportAllowance"
            value={formData.nirvinTransportAllowance}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>PF Number</label>
          <input
            type="number"
            name="nirvinPf"
            value={formData.nirvinPf}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>ESI Number</label>
          <input
            type="text"
            name="nirvinEsiNumber"
            value={formData.nirvinEsiNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Tax</label>
          <input
            type="number"
            name="nirvinTax"
            value={formData.nirvinTax}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label> Net Salary</label>
          <input
            type="number"
            name="nirvinNetSalary"
            value={formData.nirvinNetSalary}
            readOnly // Make it read-only since it's calculated
          />
        </div>

        <div>
          <label>Gross Salary</label>
          <input
            type="number"
            name="nirvinGrossSalary"
            value={formData.nirvinGrossSalary}
            readOnly // Make it read-only since it's calculated
          />
        </div>

        <div>
          <label>Working Days</label>
          <input
            type="number"
            name="nirvinWorkingDays"
            value={formData.nirvinWorkingDays}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Present Days</label>
          <input
            type="number"
            name="nirvinPresentDays"
            value={formData.nirvinPresentDays}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>PF Amount</label>
          <input
            type="number"
            name="nirvinPfAmount"
            value={formData.nirvinPfAmount}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Incentives</label>
          <input
            type="number"
            name="nirvinIncentives"
            value={formData.nirvinIncentives}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Date of Issuing</label>
          <input
            type="date"
            name="nirvinDateOfIssuing"
            value={formData.nirvinDateOfIssuing}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit">Add Payslip</button>
      </form>
    </div>
  );
};

export default NAddPayslip;
