// src/pvdDashboard.js
import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './NDashboard.css'; // Ensure you have the correct styling

const PVDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Sidebar open/close state

  const toggleSidebar = () => {
    setSidebarOpen(prevState => !prevState);
  };

  return (
    <div className={`pv-dashboard ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <aside className={`pv-sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          {sidebarOpen ? 'Close' : 'Open'}
        </button>
        <nav className={`sidebar-nav ${sidebarOpen ? '' : 'hidden'}`}>
          <ul>
            <li>
              <NavLink 
                to="/nirvin-dashboard/home" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/add-employee" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Add Employee
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/view-employees" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                View Employees
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/generate-salary-slip" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Generate Salary Slip
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/view-salary-slips" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                View Salary Slips
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/roll-offer-letter" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Roll Offer Letter
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/view-offer-letters" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                View Offer Letters
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/roll-terminationletter" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Roll Termination Letter
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/view-termination-letters" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                View Termination Letters
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/roll-experience-letter" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Roll Experience Letter
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/view-experience-letters" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                View Experience Letters
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/nirvin-dashboard/generate-id-card" 
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Generate ID Card
              </NavLink>
            </li>
          </ul>
        </nav>
      </aside>

      <main className="pv-content">
        <Outlet />
      </main>
    </div>
  );
};

export default PVDashboard;
