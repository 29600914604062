import React, { useEffect, useState } from "react";
import axios from "axios";
import './ViewOfferLetters.css'; // Import a CSS file for styling
import logoo from './logo.jpg'; // Company logo
import sign from './sign.png'; // Signature image
import './RollOfferLetter.css';

const NViewOfferLetters = () => {
  const [offerLetters, setOfferLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [searchId, setSearchId] = useState("");

  // Fetch offer letters on component mount
  useEffect(() => {
    const fetchOfferLetters = async () => {
      try {
        const response = await axios.get("https://megafamilyfoundation.org/api/nirvin-offer-letters");
        setOfferLetters(response.data);
      } catch (error) {
        setError("Error fetching offer letters");
        console.error("Error fetching offer letters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOfferLetters();
  }, []);

  const handleDelete = async (id) => {
    const passKey = "NFPRAJ99";
    const userInput = window.prompt("Please enter the pass key to confirm deletion:");

    if (userInput !== passKey) {
      alert("Invalid pass key. Deletion aborted.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this offer letter?")) {
      try {
        await axios.delete(`https://megafamilyfoundation.org/api/nirvin-offer-letters/${id}`);
        setOfferLetters((prev) => prev.filter((letter) => letter._id !== id));
      } catch (error) {
        console.error("Error deleting offer letter:", error);
      }
    }
  };

  const handlePrint = (offerLetter) => {
    const monthlySalary = parseFloat(offerLetter.nirvinSalary); // Adjusted to reflect new schema
    const annualSalary = (monthlySalary * 12).toFixed(2);
    
    const startDate = new Date(offerLetter.nirvinStartDate); // Adjusted to reflect new schema
    const responseDueDate = new Date(startDate);
    responseDueDate.setDate(startDate.getDate() - 2);
    
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Offer Letter</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 20px; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .offer-letter { 
                max-width: 700px; 
                margin: auto; 
                padding: 30px; 
                background: white; 
                border: none; 
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
                border-radius: 5px; 
                font-size: 16px; 
              }
              .header { 
                text-align: center; 
                margin-bottom: 20px; 
              }
              .header img { 
                width: 80px; 
                margin-bottom: 10px; 
              }
              .header h2 { 
                font-size: 24px; 
                margin: 0; 
              }
              .header p { 
                font-size: 14px; 
                margin: 4px 0; 
              }
              .greeting { 
                margin: 20px 0; 
                font-size: 18px; 
                font-weight: bold; 
              }
              .content { 
                margin: 20px 0; 
                font-size: 16px; 
              }
              .footer { 
                display: grid; 
                grid-template-columns: 1fr 1fr; 
                gap: 20px; 
                margin-top: 30px; 
                font-size: 14px; 
                text-align: center; 
                align-items: center; 
              }
              .signature-box img { 
                height: 60px; 
                width: 100px; 
              }
              .signature-box p { 
                margin-top: 10px; 
                font-weight: bold; 
              }
              .signature-b p { 
                margin-top: 66px; 
                font-weight: bold; 
              }
            }
          </style>
        </head>
        <body>
          <div class="offer-letter">
            <div class="header">
              <img src=${logoo} alt="Company Logo" />
                <h2>Nirvin Fertilizer and Pesticides Private Limited</h2>
              <p>11-28, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: bgudivaka@gmail.com</p>
              <p>CIN: U10509AP2023PTC113421</p>
              <h3>Offer Letter</h3>
            </div>
    
            <div class="greeting">
              Dear ${offerLetter.nirvinName}, <!-- Adjusted to reflect new schema -->
            </div>
            
            <div class="content">
              <p>We are pleased to offer you the position of <strong>${offerLetter.nirvinPosition}</strong> at Nirvin Fertilizer and Pesticides Private Limited.</p>
              
              <p>Your starting salary will be <strong>${offerLetter.nirvinSalary} per month</strong>, which equates to an annual salary of <strong>${annualSalary} per annum</strong>. Your expected start date is <strong>${startDate.toLocaleDateString()}</strong>.</p>
              
              <p>Your regular working hours will be from <strong>10:00 AM</strong> to <strong>06:00 PM</strong>, Monday through Friday.</p>
              
              <p>This offer is subject to a probation period of <strong>45 days</strong>, during which your performance will be reviewed.</p>
              
              <p>In addition to your salary, you will be entitled to the following benefits:</p>
              <ul>
                <li>Health insurance coverage</li>
                <li>Paid time off and holidays</li>
                <li>Professional development opportunities</li>
                <li>Participation in employee wellness programs</li>
              </ul>
    
              <p>We believe your skills and experiences will be a valuable addition to our team. Please confirm your acceptance of this offer by signing and returning this letter by <strong>${responseDueDate.toLocaleDateString()}</strong>.</p>
            </div>
    
            <div class="footer">
              <div class="signature-b">
                <p><strong>Employee Signature</strong></p>
              </div>
              <div class="signature-box">
                <img src=${sign} alt="Director Signature" />
                <p><strong>Director Signature</strong></p>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  // Filter offer letters based on the search month, year, and ID
  const filteredOfferLetters = offerLetters.filter(letter => {
    const letterDate = new Date(letter.nirvinDateOfIssuing); // Adjusted to reflect new schema

    const monthMatch = letterDate.toLocaleString('default', { month: 'long' }).toLowerCase().includes(searchMonth.toLowerCase());
    const yearMatch = letterDate.getFullYear().toString().includes(searchYear);
    const idMatch = letter.nirvinEmpid && letter.nirvinEmpid.includes(searchId); // Adjusted to reflect new schema

    return monthMatch && yearMatch && idMatch;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="offer-letters-title">Offer Letters</h2>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by Employee ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by month name"
          value={searchMonth}
          onChange={(e) => setSearchMonth(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by year"
          value={searchYear}
          onChange={(e) => setSearchYear(e.target.value)}
        />
      </div>
      <table className="offer-letter-table">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Position</th>
            <th>Date of Issuing</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredOfferLetters.map((letter) => (
            <tr key={letter._id}>
              <td>{letter.nirvinEmpid}</td>
              <td>{letter.nirvinName}</td>
              <td>{letter.nirvinPosition}</td>
              <td>{new Date(letter.nirvinDateOfIssuing).toLocaleDateString()}</td>
              <td>
                <button onClick={() => handlePrint(letter)}>Print</button>
                <button onClick={() => handleDelete(letter._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NViewOfferLetters;
