// src/Dashboard.js
import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(true); // Sidebar open/close state

  const toggleSidebar = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className={`dashboard-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <aside className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
          {isOpen ? 'Close' : 'Open'}
        </button>
        <nav className={`sidebar-links ${isOpen ? '' : 'hidden'}`}>
          <ul>
          
          <li><NavLink to="/dashboard/home" activeClassName="active">Home</NavLink></li>
            <li><NavLink to="/dashboard/generate-receipt" activeClassName="active">Generate Receipt</NavLink></li>
            <li><NavLink to="/dashboard/view-receipts" activeClassName="active">View Receipts</NavLink></li>
            <li><NavLink to="/dashboard/add-employee" activeClassName="active">Add Employee</NavLink></li>
            <li><NavLink to="/dashboard/view-employees" activeClassName="active">View Employees</NavLink></li>
            <li><NavLink to="/dashboard/add-beneficiary" activeClassName="active">Add Beneficiary</NavLink></li>
            <li><NavLink to="/dashboard/view-beneficiary" activeClassName="active">View Beneficiaries</NavLink></li>
            <li><NavLink to="/dashboard/generate-salary-slip" activeClassName="active">Generate Salary Slip</NavLink></li>
            <li><NavLink to="/dashboard/view-salary-slips" activeClassName="active">View Salary Slips</NavLink></li>
            <li><NavLink to="/dashboard/roll-offer-letter" activeClassName="active">Roll Offer Letter</NavLink></li>
            <li><NavLink to="/dashboard/view-offer-letters" activeClassName="active">View Offer Letters</NavLink></li>
            <li><NavLink to="/dashboard/roll-terminationletter" activeClassName="active">Roll Termination Letter</NavLink></li>
            <li><NavLink to="/dashboard/view-termination-letters" activeClassName="active">View Termination Letters</NavLink></li>
            <li><NavLink to="/dashboard/roll-experience-letter" activeClassName="active">Roll Experience Letter</NavLink></li>
            <li><NavLink to="/dashboard/view-experience-letters" activeClassName="active">View Experience Letters</NavLink></li>
            <li><NavLink to="/dashboard/roll-id-card" activeClassName="active">Generate ID Card</NavLink></li>
          </ul>
        </nav>
      </aside>

      <main className="dashboard-content">
        <Outlet />
      </main>
    </div>
  );
};

export default Dashboard;
