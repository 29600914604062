// src/components/NViewExperienceLetters.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import './ViewOfferLetters.css'; // Import a CSS file for styling
import logoo from './logo.jpg'; // Company logo
import sign from './sign.png'; // Signature image
import './RollOfferLetter.css';

const NViewExperienceLetters = () => {
  const [experienceLetters, setExperienceLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"

  // Fetch existing experience letters from the backend
  useEffect(() => {
    const fetchExperienceLetters = async () => {
      try {
        const response = await axios.get("https://megafamilyfoundation.org/api/nirvin-experience-letters");
        setExperienceLetters(response.data);
      } catch (error) {
        console.error("Error fetching experience letters", error);
        setError("Error fetching experience letters");
      } finally {
        setLoading(false);
      }
    };

    fetchExperienceLetters();
  }, []);

  // Handle deletion of experience letter
  const handleDelete = async (id) => {
    const passKey = "NFPRAJ99"; // Replace with your actual pass key
  
    // Prompt for the pass key
    const userInput = prompt("Please enter the pass key to confirm deletion:");
  
    // Check if the entered pass key is correct
    if (userInput !== passKey) {
      alert("Incorrect pass key. Deletion canceled.");
      return; // Exit the function if the pass key is incorrect
    }
  
    // Confirm deletion
    if (window.confirm("Are you sure you want to delete this experience letter?")) {
      try {
        await axios.delete(`https://megafamilyfoundation.org/api/nirvin-experience-letters/${id}`);
        setExperienceLetters((prev) => prev.filter((letter) => letter._id !== id));
        alert("Experience letter deleted successfully!");
      } catch (error) {
        console.error("Error deleting experience letter:", error);
        alert("Failed to delete experience letter. Check console for details.");
      }
    }
  };
  

  // Print functionality for individual letters
  const handlePrint = (experienceLetter) => {
    const startDate = new Date(experienceLetter.nirvinDateOfJoining);
    const endDate = new Date(experienceLetter.nirvinLeavingDate);
  
    // Calculate tenure in years and months
    let tenureYears = endDate.getFullYear() - startDate.getFullYear();
    let tenureMonths = endDate.getMonth() - startDate.getMonth();
  
    if (tenureMonths < 0) {
      tenureYears -= 1;
      tenureMonths += 12;
    }
  
    const tenure = `${tenureYears} year${tenureYears !== 1 ? 's' : ''} and ${tenureMonths} month${tenureMonths !== 1 ? 's' : ''}`;
  
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Experience Letter</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 20px; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .experience-letter { 
                max-width: 700px; 
                margin: auto; 
                padding: 30px; 
                background: white; 
                border: none; 
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
                border-radius: 5px; 
                font-size: 16px; 
              }
              .header { 
                text-align: center; 
                margin-bottom: 20px; 
              }
              .header img { 
                width: 80px; 
                margin-bottom: 10px; 
              }
              .header h2 { 
                font-size: 24px; 
                margin: 0; 
              }
              .header p { 
                font-size: 14px; 
                margin: 4px 0; 
              }
              .content { 
                margin: 20px 0; 
                font-size: 16px; 
                line-height: 1.5; 
              }
              .footer { 
                display: grid; 
                grid-template-columns: 1fr 1fr; 
                gap: 20px; 
                margin-top: 30px; 
                font-size: 14px; 
                text-align: center; 
                align-items: center; 
              }
              .signature-box img { 
                height: 60px; 
                width: 100px; 
              }
              .signature-box p { 
                margin-top: 10px; 
                font-weight: bold; 
              }
              .signature-b p { 
                margin-top: 66px; 
                font-weight: bold; 
              }
            }
          </style>
        </head>
        <body>
          <div class="experience-letter">
            <div class="header">
              <img src="${logoo}" alt="Company Logo" />
              <h2>Nirvin Fertilizer and Pesticides Private Limited</h2>
              <p>11-28, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: bgudivaka@gmail.com</p>
              <p>CIN: U10509AP2023PTC113421</p>
              <h3>Experience Letter</h3>
            </div>
            <div class="content">
              <p>This is to certify that <strong>${experienceLetter.nirvinName}</strong> has been employed with us as <strong>${experienceLetter.nirvinPosition}</strong> from <strong>${startDate.toLocaleDateString()}</strong> to <strong>${endDate.toLocaleDateString()}</strong>.</p>
              <p>Throughout their tenure of <strong>${tenure}</strong>, <strong>${experienceLetter.nirvinName}</strong> displayed exceptional skills in <strong>${experienceLetter.nirvinSkills || "various tasks relevant to their position"}</strong>, contributing significantly to our team's success.</p>
              <p>We are grateful for their hard work and commitment to excellence. We are confident that the skills and experiences they have gained during their tenure will greatly benefit them in their future endeavors.</p>
              <p>We wish them all the best in their future career path and are happy to provide this letter as a testament to their contributions to our organization.</p>
            </div>
            <div class="footer">
              <div class="signature-b">
                <p><strong>Employee Signature</strong></p>
              </div>
              <div class="signature-box">
                <img src="${sign}" alt="Director Signature" />
                <p><strong>Director Signature</strong></p>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  // Print functionality for the entire table
  const handlePrintTable = () => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Experience Letters Table</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
              margin: 0;
              padding: 20px;
              background-color: #f7f7f7;
              color: #333;
            }
            .experience-letters-table {
              width: 100%;
              border-collapse: collapse;
            }
            .experience-letters-table th,
            .experience-letters-table td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            .experience-letters-table th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <h2>Experience Letters</h2>
          <table class="experience-letters-table">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Position</th>
                <th>Joining Date</th>
                <th>Leaving Date</th>
              </tr>
            </thead>
            <tbody>
              ${experienceLetters.map(letter => `
                <tr>
                  <td>${letter.nirvinName}</td>
                  <td>${letter.nirvinPosition}</td>
                  <td>${new Date(letter.nirvinDateOfJoining).toLocaleDateString()}</td>
                  <td>${new Date(letter.nirvinLeavingDate).toLocaleDateString()}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  // Handle sorting
  const sortedExperienceLetters = [...experienceLetters].sort((a, b) => {
    const aName = a.nirvinName.toLowerCase();
    const bName = b.nirvinName.toLowerCase();

    if (sortOrder === "asc") {
      return aName.localeCompare(bName);
    } else {
      return bName.localeCompare(aName);
    }
  });

  // Filter based on search term
  const filteredExperienceLetters = sortedExperienceLetters.filter(letter =>
    letter.nirvinName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading experience letters...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="letter-list-title">Existing Experience Letters</h2>
      <div className="search-sort">
        <input 
          type="text" 
          placeholder="Search by employee name..." 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
        <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
          <option value="asc">Sort by Name (A-Z)</option>
          <option value="desc">Sort by Name (Z-A)</option>
        </select>
        <button onClick={handlePrintTable}>Print All Experience Letters</button>
      </div>
      {filteredExperienceLetters.length === 0 ? (
        <p>No experience letters found.</p>
      ) : (
        <table className="experience-letters-table">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Position</th>
              <th>Joining Date</th>
              <th>Leaving Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredExperienceLetters.map((letter) => (
              <tr key={letter._id}>
                <td>{letter.nirvinName}</td>
                <td>{letter.nirvinPosition}</td>
                <td>{new Date(letter.nirvinDateOfJoining).toLocaleDateString()}</td>
                <td>{new Date(letter.nirvinLeavingDate).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => handlePrint(letter)}>Print</button>
                  <button onClick={() => handleDelete(letter._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NViewExperienceLetters;
